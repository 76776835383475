<template>
  <div class="modal fade handelbay-modal" id="compare-planes" tabindex="-1" role="dialog" aria-labelledby="compare-planesLabel">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div id="agendatudemo">
          <div class="modal-header text-center">
            <h3 class="modal-title" id="compare-planesLabel" style="color: black;font-weight: bold;font-family: inherit;">
                {{ $t('lang.modal_plans.plan_comparison') }}
            </h3>
          </div>
          <div class="modal-body">
            <div class="title-body modal_tabs">
              <div class="tabs-handelbay-planes text-center">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs" role="tablist">
                  <li role="presentation" class="buyer active" id="li_comp">
                    <a href="#buyerM" aria-controls="buyerM" role="tab" data-toggle="tab">
                        <span>
                            {{ $t('lang.modal_plans.buyer') }}
                        </span>
                    </a>
                  </li>
                  <li role="presentation" class="dual" id="li_dual">
                    <a href="#dualM" aria-controls="dualM" role="tab" data-toggle="tab">
                        {{ $t('lang.modal_plans.modal') }}
                    </a>
                  </li> 
                  <li role="presentation" class="provider" id="li_proveedor">
                    <a href="#providerM" aria-controls="providerM" role="tab" data-toggle="tab">
                        {{ $t('lang.modal_plans.provider') }}
                    </a>
                  </li>                                                                       
                </ul>
              </div>
            </div>

            <!-- COMPRADOR -->
            
            <div class="tab-content">
              <div role="tabpanel" class="tab-pane active" id="buyerM">
                <div class="scroll_table">
                  <div class="scroll_table-content">       
                    <!-- PRECIO -->
                    <table class="table_price table planes-users">
                        <caption>
                            {{ $t('lang.modal_plans.plan_prices') }}
                        </caption>
                      <thead>
                        <tr>
                          <th colspan="2"></th>
                          <th>    
                            <h3 class="planes-users_title">
                                {{ $t('lang.modal_plans.basic') }}
                            </h3>
                            <span class="planes-users_text">
                                {{ $t('lang.modal_plans.1_user') }}
                            </span>
                          </th>
                          <th>    
                            <h3 class="planes-users_title">
                                {{ $t('lang.modal_plans.single') }}
                            </h3>
                            <span class="planes-users_text">
                                {{ $t('lang.modal_plans.1_user') }}
                            </span>
                          </th>
                          <th>    
                            <h3 class="planes-users_title">
                                {{ $t('lang.modal_plans.team') }}
                            </h3>
                            <span class="planes-users_text">
                                {{ $t('lang.modal_plans.5_users') }}
                            </span>
                          </th>
                          <th>    
                            <h3 class="planes-users_title">
                                {{ $t('lang.modal_plans.pro') }}
                            </h3>
                            <span class="planes-users_text">
                                {{ $t('lang.modal_plans.30_users') }}
                            </span>
                          </th>
                          <th style="width: 35px;"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="col" colspan="7" class="title">
                            {{ $t('lang.modal_plans.price') }}
                          </th>
                          <td rowspan="4" class="more">-</td>
                        </tr>
                        <tr class="tr-body" style="">
                          <th scope="col" colspan="2" class="subtitle">
                            {{ $t('lang.modal_plans.annual') }}
                          </th>
                          <td class="text-center">
                            $0
                          </td>
                          <td class="text-center">
                            $190.000
                          </td>
                          <td class="text-center">
                            $650.000
                          </td>
                          <td class="text-center">
                            $1.350.000
                          </td>
                        </tr>
                        <tr class="tr-body" style="">
                          <th scope="col" colspan="2" class="subtitle">
                            {{ $t('lang.modal_plans.biannual') }}
                          </th>
                          <td class="text-center">
                            $0
                          </td>
                          <td class="text-center">
                            $247.000
                          </td>
                          <td class="text-center">
                            $845.000
                          </td>
                          <td class="text-center">
                            $1.755.000
                          </td>
                        </tr>
                        <tr class="tr-body" style="">
                          <th scope="col" colspan="2" class="subtitle">
                            {{ $t('lang.modal_plans.monthly') }}
                          </th>
                          <td class="text-center">
                            $0
                          </td>
                          <td class="text-center">
                            $275.500
                          </td>
                          <td class="text-center">
                            $942.500
                          </td>
                          <td class="text-center">
                            $1.957.500
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- RED SOCIAL EMPRESARIAL -->
                    <table class="table_price table">
                      <caption>
                        {{ $t('lang.modal_plans.business_social_network') }}
                    </caption>
                      <tbody>
                        <tr>
                          <th scope="col" colspan="7" class="title">
                            {{ $t('lang.modal_plans.business_social_network') }}
                          </th>
                          <td rowspan="4" class="more">-</td>
                        </tr>
                        <tr class="tr-body" v-for="head of headers" :key="head.id">
                          <th scope="col" colspan="2" class="subtitle">
                            {{head}}
                          </th>
                          <td class="text-center">
                            <span class="point">✔</span>
                          </td>
                          <td class="text-center">
                            <span class="point">✔</span>
                          </td>
                          <td class="text-center">
                            <span class="point">✔</span>
                          </td>
                          <td class="text-center">
                            <span class="point">✔</span>
                          </td>
                          <td class="text-center">
                            <span class="point">✔</span>
                          </td>                                   
                        </tr>
                      </tbody>
                    </table>

                    <!-- PROCESO DE COMPRA  -->
                    <table class="table_price table">
                        <caption>
                            {{ $t('lang.modal_plans.purchasing_process') }}
                        </caption>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="7" class="title" style="border-bottom: 0px;">
                                    {{ $t('lang.modal_plans.purchasing_process') }}
                                </th>
                                <td rowspan="30" class="more" data-original-title="" title="" style="width: 35px;">-</td>
                            </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.posts_per_month') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">2</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">10</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">
                                                {{ $t('lang.modal_plans.posts_per_month') }}
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">{{ $t('lang.modal_plans.posts_per_month') }}</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">{{ $t('lang.modal_plans.posts_per_month') }}</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.requisitions') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.grouping_of_requisitions') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.requisition_approvals') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.media_delivery') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.purchase_with_excel') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.publication_of_public_processes') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.publication_of_private_processes') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.event_log') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.schedule_processes') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.replicate_processes') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.receipt_of_quotes') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.comparisons') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.comparison_with_weighted_average') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.export_comparatives_in_PDF') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.request_for_requotes') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.purchase_approvals') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.export_quotes_to_PDF') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.generation_of_purchase_orders') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.purchases_by_quote') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.purchases_by_product') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.post_purchase') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.ratings') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.private_chat_by_process') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.group_chat_by_process') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.read_/_unread') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.open_invitations') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.limited_invitations') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.cop/usd') }}
                                    </th>
                                      <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                        </tbody>
                    </table>

                    <!-- PERFIL COMPRADOR -->
                    <table class="table_price table">
                        <caption>
                            {{ $t('lang.modal_plans.buyer_profile') }}
                        </caption>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="7" class="title" style="border-bottom: 0px;">
                                    {{ $t('lang.modal_plans.buyer_profile') }}
                                </th>
                                <td rowspan="9" class="more" data-original-title="" title="" style="width: 35px;">-</td>
                            </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.categories') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.products') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.company_areas') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.price_history') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.dashboard') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.budget') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.favorite_providers') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                                <tr class="tr-body" style="">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.vendor_classification') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                        </tbody>
                    </table>

                    <!-- VARIOS -->
                    <table class="table_price table">
                        <caption>
                            {{ $t('lang.modal_plans.several') }}
                        </caption>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="7" class="title" style="border-bottom: 1px solid rgb(195, 195, 195);">
                                    {{ $t('lang.modal_plans.several') }}
                                </th>
                                <td rowspan="" class="more" data-original-title="" title="" style="width: 35px;">+</td>
                            </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.documentation') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.multiuser') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.excel') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.UNSPSC_encoding') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.process_transfer') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.multiple_user_roles') }}
                                    </th>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="point">✔</span>
                                    </td>                                   
                                </tr>
                        </tbody>
                    </table>

                    <!-- GENERALES -->
                    <table class="table_price table">
                        <caption>
                            {{ $t('lang.modal_plans.general') }}
                        </caption>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="7" class="title">
                                    {{ $t('lang.modal_plans.general') }}
                                </th>
                                <td rowspan="4" class="more" data-original-title="" title="">+</td>
                            </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.users_quantity') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">1</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">4</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">8</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">16</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">24</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.support') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.account') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.notifications') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.general_settings') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.referrals') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.plans') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                        </tbody>
                    </table>

                    <!-- REPORTES -->
                    <table class="table_price table">
                        <caption>
                            {{ $t('lang.modal_plans.reports') }}
                        </caption>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="7" class="title">
                                    {{ $t('lang.modal_plans.reports') }}
                                </th>
                                <td rowspan="4" class="more" data-original-title="" title="">+</td>
                            </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.process') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.users') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                                <tr class="tr-body" style="display: none;">
                                    <th scope="col" colspan="2" class="subtitle">
                                        {{ $t('lang.modal_plans.personalized') }}
                                    </th>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
                <hr>
                <div class="text-center buttons-modal">
                  <button data-dismiss="modal" class="btn btn-handelbay-success btn-transparent-success">CERRAR</button>
                  <a :href="global.sign_up" class="btn btn-handelbay-success">
                    {{ $t('lang.modal_plans.register') }}
                  </a>
                </div>                        
                <br>                               
              </div>
              <!-- PROVEEDOR -->
              <div role="tabpanel" class="tab-pane" id="providerM">                                
                <div class="scroll_table">
                  <div class="scroll_table-content">       
                    <!-- PRECIO -->
                    <table class="table_price_prov table planes-users">
                        <caption>
                            {{ $t('lang.modal_plans.plan_prices') }}
                        </caption>
                        <thead>
                          <tr>
                            <th colspan="2"></th>
                            <th>    
                              <h3 class="planes-users_title">
                                {{ $t('lang.modal_plans.basic') }}
                              </h3>
                              <span class="planes-users_text">
                                {{ $t('lang.modal_plans.2_users') }}
                              </span>
                            </th>
                            <th>    
                              <h3 class="planes-users_title">{{ $t('lang.modal_plans.team') }}</h3>
                              <span class="planes-users_text">{{ $t('lang.modal_plans.4_users') }}</span>
                            </th>
                            <th>    
                              <h3 class="planes-users_title">{{ $t('lang.modal_plans.pro') }}</h3>
                              <span class="planes-users_text">{{ $t('lang.modal_plans.10_users') }}</span>
                            </th>
                            <th style="width: 35px;"></th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="7" class="title" style="border-bottom: 1px solid rgb(195, 195, 195);">
                                    {{ $t('lang.modal_plans.price') }}
                                </th>
                                <td rowspan="" class="more" data-original-title="" title="" style="width: 35px;">+</td>
                            </tr>
                            <tr class="tr-body" style="display: none;">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.annual') }}
                                </th>
                                <td class="text-center">
                                    $0
                                </td>
                                <td class="text-center">
                                    $59.000
                                </td>
                                <td class="text-center">
                                    $110.000
                                </td>
                            </tr>
                            <tr class="tr-body" style="display: none;">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.biannual') }}
                                </th>
                                <td class="text-center">
                                    $0
                                </td>
                                <td class="text-center">
                                    $76.700
                                </td>
                                <td class="text-center">
                                    $143.000
                                </td>
                            </tr>
                            <tr class="tr-body" style="display: none;">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.monthly') }}
                                </th>
                                <td class="text-center">
                                    $0
                                </td>
                                <td class="text-center">
                                    $85.500
                                </td>
                                <td class="text-center">
                                    $159.500
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- RED SOCIAL EMPRESARIAL -->
                    <table class="table_price_prov table">
                        <caption>
                            {{ $t('lang.modal_plans.business_social_network') }}
                        </caption>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="7" class="title">
                                    {{ $t('lang.modal_plans.business_social_network') }}
                                </th>
                                <td rowspan="4" class="more">-</td>
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.profile') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                   
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.media_in_profile') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                   
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.reputations') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.messages') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.recommendations') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.recent_activity') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.suggested_clients') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                        </tbody>
                    </table>
                    <!-- VARIOS -->
                    <table class="table_price_prov table">
                        <caption>
                            {{ $t('lang.modal_plans.HandelBay_processes') }}
                        </caption>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="7" class="title">
                                    {{ $t('lang.modal_plans.several') }}
                                </th>
                                <td rowspan="4" class="more">-</td>
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.documentation') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                   
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.multiuser') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                   
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.multiple_user_roles') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.UNSPSC_encoding') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.process_transfer') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>                                
                        </tbody>
                    </table>
                    <!-- GENERALES -->
                    <table class="table_price_prov table">
                        <caption>
                            {{ $t('lang.modal_plans.handelBay_interactions') }}
                        </caption>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="7" class="title">
                                    {{ $t('lang.modal_plans.general') }}
                                </th>
                                <td rowspan="4" class="more">-</td>
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.users_quantity') }}
                                </th>
                                <td class="text-center">
                                    2
                                </td>
                                <td class="text-center">
                                    4
                                </td>
                                <td class="text-center">
                                    8
                                </td>
                                <td class="text-center">
                                    {{ $t('lang.modal_plans.unlimited(os)') }}
                                </td>
                                <td class="text-center">
                                    {{ $t('lang.modal_plans.unlimited(os)') }}
                                </td>                                   
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.support') }}
                                </th>
                                <td class="text-center">
                                    <span class="point"></span>
                                </td>
                                <td class="text-center">
                                    <span class="point"></span>
                                </td>
                                <td class="text-center">
                                    <span class="point"></span>
                                </td>
                                <td class="text-center">
                                    <span class="point"></span>
                                </td>
                                <td class="text-center">
                                    <span class="point"></span>
                                </td>                                   
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.account') }}
                                </th>
                                <td class="text-center">
                                    <span class="point"></span>
                                </td>
                                <td class="text-center">
                                    <span class="point"></span>
                                </td>
                                <td class="text-center">
                                    <span class="point"></span>
                                </td>
                                <td class="text-center">
                                    <span class="point"></span>
                                </td>
                                <td class="text-center">
                                    <span class="point"></span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.notifications') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.general_settings') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.referrals') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>   
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.plans') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>                             
                        </tbody>
                    </table>
                    <!-- PROCESO DE COTIZACIÓN -->
                    <table class="table_price_prov table">
                        <caption>
                            {{ $t('lang.modal_plans.quotation_process') }}
                        </caption>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="7" class="title">
                                    {{ $t('lang.modal_plans.quotation_process') }}
                                </th>
                                <td rowspan="4" class="more">-</td>
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.number_of_quotes') }}
                                </th>
                                <td class="text-center">
                                    6
                                </td>
                                <td class="text-center">
                                    15
                                </td>
                                <td class="text-center">
                                    {{ $t('lang.modal_plans.unlimited(os)') }}
                                </td>
                                <td class="text-center">
                                    {{ $t('lang.modal_plans.unlimited(os)') }}
                                </td>
                                <td class="text-center">
                                    {{ $t('lang.modal_plans.unlimited(os)') }}
                                </td>                                   
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.invitations_to_quote') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                   
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.read_/_unread') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.process_closure_notice') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.online_quotes') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.media_attachments') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>   
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.private_chat_by_process') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.group_chat_by_process') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.event_log') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>   
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.quote_by_excel') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.reception_of_ocs') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.quote_additional') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.after_sales') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.ratings') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.requotes') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.export_purchase_orders_to_pdf') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>
                            <tr class="tr-body">
                                <th scope="col" colspan="2" class="subtitle">
                                    {{ $t('lang.modal_plans.cop/usd') }}
                                </th>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>
                                <td class="text-center">
                                    <span class="point">✔</span>
                                </td>                                                             
                            </tr>               
                        </tbody>
                    </table>

                    <!-- PERFIL PROVEEDOR -->
                    <table class="table_price_prov table">
                        <caption>
                            {{ $t('lang.modal_plans.provider_profile') }}
                        </caption>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="7" class="title">
                                    {{ $t('lang.modal_plans.provider_profile') }}
                                </th>
                                <td rowspan="4" class="more">-</td>
                            </tr>
                                <tr class="tr-body">
                                    <th scope="col" colspan="2" class="subtitle">
                                    </th>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                        </tbody>
                    </table>

                    <!-- REPORTES -->
                    <table class="table_price_prov table">
                        <caption>
                            {{ $t('lang.modal_plans.reports') }}
                        </caption>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="7" class="title">
                                    {{ $t('lang.modal_plans.reports') }}
                                </th>
                                <td rowspan="4" class="more">-</td>
                            </tr>
                                <tr class="tr-body">
                                    <th scope="col" colspan="2" class="subtitle">
                                    </th>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">X</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="point">✔</span>
                                        </td>
                                </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
                <hr>
                <div class="text-center buttons-modal">
                  <button data-dismiss="modal" class="btn btn-handelbay-success btn-transparent-success">CERRAR</button>
                  <a :href="global.sign_up" class="btn btn-handelbay-success">
                    {{ $t('lang.modal_plans.register') }}
                  </a>
                </div>                        
                <br>
              </div> <!-- .buyerM -->
              <div role="tabpanel" class="tab-pane text-center" id="dualM">
                  <div class="title">
                      <h3>
                        {{ $t('lang.modal_plans.the_best_of_two_worlds') }}
                    </h3>
                  </div>
                  <p class="text">
                    {{ $t('lang.modal_plans.remember_that_for_the_DUAL_profile_you_must_choose_a_buyer_plan_and_a_supplier_plan') }}
                  </p>
                  <p class="plan_text1 plan_txt text-center">
                    {{ $t('lang.modal_plans.buyer_plan') }}
                  </p>
                  <span class="more">
                      +
                  </span>
                  <p class="plan_text2 plan_txt text-center">
                    {{ $t('lang.modal_plans.provider_plan') }}
                  </p>
                  <div class="text-center buttons-modal">
                    <a href="" class="btn btn-handelbay-success" data-dismiss="modal">
                        {{ $t('lang.modal_plans.to_close') }}
                    </a>
                    <a :href="global.sign_up" class="btn btn-handelbay-success">
                        {{ $t('lang.modal_plans.register') }}
                    </a>
                  </div> 
                  <br>
              </div><!-- .dualM -->
            </div>                        
          </div>
          <!-- end primera parte  -->
        </div>                
      </div>
        <!-- .modal-content -->
    </div>
  </div>
    <!-- END MODAL AGENDA TU DEMO AHORA -->
</template>
<script>
import global from "@/components/Global.vue"
export default {
  data(){
    return{
      headers: ["Perfil","Multimedia en el perfil","Reputaciones","Mensajes","Recomendaciones", "Seguidores","Actividad reciente","Clientes sugeridos"],
      global: global
    }
  }
}
</script>